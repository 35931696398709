/* Typewriter.css */
.typewriter {
    display:block;
    overflow: hidden;
    /* Ensures the text is not revealed until the animation */
    // border-right: 0.08em solid orange;
    /* The typewriter cursor */
    // white-space: nowrap;
    /* Keeps the content on a single line */
    letter-spacing: 0.15em;
    /* Adjust as needed */
    animation: typing 1s steps(30, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: orange;
    }
}

.header-title {

    font-size: 6.6rem;
}

@media screen and (max-width: 1135px) {
    .header-title {
        font-size: 5.6rem;
    }
}

@media screen and (max-width: 768px) {
    .header-title {
        font-size: 4.4rem;
    }
}