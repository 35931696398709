$logo:#04D3BC;
$dark:#029383;
$lite:#36dbc9;

// .header-title-1 {
//     color: $lite;
//     font-size: 8.6rem;
// }

.header-title {

    font-size: 6.6rem;

    spam {
        color: $logo;
    }
}

@media screen and (max-width: 1135px) {
    .header-title {
        font-size: 5.6rem;
    }
}

@media screen and (max-width: 768px) {
    .header-title {
        font-size: 4.4rem;
    }
}