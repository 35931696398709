$logo:#04D3BC;
$dark:#029383;
$lite:#36dbc9;

.Plans-Prices {
    border: 2px solid rgb(255, 255, 255);
    border-radius: 15px;
    // height: 415px;
}

.Plans-Titles_prices {
    font-size: 4.5rem;
}

.Plans_lists {
    display: inline;

    li {
        display: block;
    }

}

.plan-Button {
    border-radius: 40px !important;
}

.plan-Button_arrow {
    width: 30px !important;
    height: 30px !important;
    background: #000000;
    border-radius: 50px;
    float: right;
    color: #ffffff;
}