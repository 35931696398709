$logo:#04D3BC;
$dark:#029383;
$lite:#36dbc9;


.Images-Box {
    border: 1px solid $logo;
    border-radius: 9px;
}

.Img-Box-title {
    color: $logo;
    font-size: 3rem;
}