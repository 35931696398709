// .app__navbar-smallscreen_overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100vh;
//     background: var(--color-black);
//     transition: .5s ease;

//     flex-direction: column;
//     z-index: 5;
// }

// .app__navbar-smallscreen_overlay .overlay__close {
//     font-size: 27px;
//     color: var(--color-golden);
//     cursor: pointer;

//     position: absolute;
//     top: 20px;
//     right: 20px;
// }

.App {
    min-height: 100vh;
    display: flex;
    align-content: space-around;
    justify-content: flex-end;
}

.sidebar {
    border-right: 1px solid rgb(100, 100, 100);
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: url(../../assets/3.jpg) !important;
    // background: #03001F;
}

.slide-bottom {
    display: contents;
}

.upperSide {
    border-bottom: 1px solid rgb(100, 100, 100);
    height: 21%;
}

// .Logo {
//     width: 64px;
// }

.query {
    display: flex !important;
    justify-content: left !important;
    margin: 1rem auto !important;
    margin-bottom: 1rem;
    // width: 25rem !important;
    margin-bottom: 2.5rem !important;
    border-radius: 0.5rem !important;
    // border-color: #0AA89D !important;
}

.MidSide {
    height: 52%;
    border-bottom: 1px dotted rgb(100, 100, 100);
}

.secondLastSide {
    height: 12%;
    border-bottom: 1px dotted rgb(100, 100, 100);
}


.lowerSide {
    height: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.lowerSide>a {
    // width: 20px;
    color: #dedede;
}

// .listitems {
//     display: inline-block !important;
//     margin: 0.75rem !important;
//     font-size: 1.8rem !important;
// }

// .listitemsImg {
//     width: 28px;
//     margin: 1rem;
//     padding-right: 1rem;
// }

.addBtn {
    width: 20px;
}

.main {
    min-height: calc(100vh - 14rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 6rem 0rem;
    // padding: 6rem 8rem;
    // margin-bottom: 0;
    padding-bottom: 0;
}

.chats {
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
    // width: 100%;
    // max-width: 70rem;
    height: calc(100vh - 16rem);
}

.bot {
    // background: #1c1e3a;
    // background: #2d6662;
    // background: #232d52;
    background: #D9D9D954;
    width: -webkit-initial;
    width: -moz-initial;
    // width: fit-content;
    width: initial;
}

.bot,
.chatimg {
    border-radius: .5rem;
}

.chat {
    align-items: flex-start;
    display: flex;
    font-size: 1.25rem;
    margin: 1rem;
    padding: 2rem 3rem;
    text-align: justify;
    // align-items: center;
}

.chatimg {
    margin-right: 2rem;
    object-fit: cover;
    width: 3.5rem;
}

.inp {
    // background: #1c1e3a;
    background: #D9D9D954;
    border-radius: .5rem !important;
    padding: .5rem;
    // width: 68rem;
}

.chatfooter,
.inp {
    align-items: center;
    display: flex;
}

.inp>input,
.send {
    background: #0000;
    border: none;
    outline: none;
}

.inp>input {
    color: #ffffff !important;
    font-size: 1.25rem;
    padding: 1.25rem;
    width: calc(100% - 3rem);
}

.gptnotic {
    display: flex;
    justify-content: center;
    margin: 2rem 0 0;
}

.chatfooter>p {
    margin: 2rem 0;
}

.txt {
    line-height: 2.4rem;
    font-size: 1.6rem;
    color: rgb(222, 222, 222, 1);
}

.midBtn {
    background: #0AA89D !important;
}


@media screen and (max-width: 768px) {
    .chat {
        margin: 1rem;
        padding: .5rem 1rem;
    }

    .txt {
        line-height: 1.6rem;
        font-size: 1rem;
        color: rgb(255, 255, 255);
    }

    .chatimg {
        margin-right: 1rem;
        width: 3rem;
    }
}