$logo:#04D3BC;
$dark:#029383;
$lite:#36dbc9;


.Button-border {
    border: 1px solid white !important;
    border-radius: 11px !important;

    span {
        color: $lite;
    }
}