$logo:#04D3BC;
$dark:#029383;
$lite:#36dbc9;

$image-path : "../../assets/Plan-title-bg.jpg";

.Pricing-title {
    font-size: 3rem;
    // background-image: url($image-path);
    // background-repeat: no-repeat;
}
