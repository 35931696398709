$logo:#04D3BC;
$dark:#029383;
$lite:#36dbc9;

.Navbar-chatnow {
    border: 1px solid $lite !important;
    border-radius: 11px !important;
}

.Navbar-mb-menu {
    flex-direction: column;
    position: absolute;
    top: 58px;
    right: 28px;
    padding: 1rem;
    // background: #232d52;
    background: $dark;
    border-radius: 8px;
}
.MuiBadge-badge{
    font-size: 13px !important;
    background: #04d3bc !important;
}

// .xario-bage_beta {
//     top: auto;
//     right: -13px;
//     font-size: 12px;
//     background: #04D3BC;
// }