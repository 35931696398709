// .App{
//   min-height: 100vh;
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  background: url(./assets/1.jpg) !important;
  // background: rgb(3, 0, 31) !important;
  color: #fff !important;
  // font-family: Ubuntu, sans-serif;
  // font-family: 'Cabin', monospace;
  // font-family: 'Roboto', sans-serif;
  font-family: 'Inter', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loaderx {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// .spinner {
//   border: 8px solid rgba(0, 0, 0, 0.3);
//   border-top: 8px solid #3498db;
//   border-radius: 50%;
//   width: 50px;
//   height: 50px;
//   animation: spin 1s linear infinite;
//   margin-bottom: 10px;
// }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}